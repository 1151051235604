































































import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    options: {
      type: Array,
      default: () => []
    },
    title: String,
    image: String,
    value: [String, Number],
    icon: String,
    subtitle: {
      type: String,
      default: 'Sold totale'
    },
    variant: {
      type: String,
      default: 'green',
      validator: value => /green|blue|red/.test(value)
    },
    currency: Boolean,
    number: Boolean
  }
})
