





































































































































import Vue, { VueConstructor } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import StatCard from '@/components/StatCard.vue'
import Organization from '../../../store/models/Organization'
import Logs from '../../../store/models/Logs'
import VBarChart from '@/components/VBarChart.vue'

import moment from 'moment'
import api from '@/api'

import AccountMixin from '../../../mixins/accountMixin'

const metricsUrls: Record<string, string> = {
  CreditLineProvision: '/credit-line-provisions/metrics',
  CreditLineDeadline: '/credit-line-deadlines/metrics',
  DispatchingCredit: '/dispatching-credits/metrics'
}

const reportsUrls: Record<string, string> = {
  CreditLineProvision: '/credit-line-provisions/report',
  CreditLineDeadline: '/credit-line-deadlines/report?state=paid',
  DispatchingCredit: '/dispatching-credits/report'
}

const metricsTitles: Record<string, string> = {
  CreditLineProvision: 'Décaissements',
  CreditLineDeadline: 'Remboursements',
  DispatchingCredit: 'Bénéficiaires'
}

const metricsColors: Record<string, string> = {
  CreditLineProvision: '#588555',
  CreditLineDeadline: '#1162ac',
  DispatchingCredit: '#C35764'
}

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    StatCard,
    VBarChart
  },

  data() {
    return {
      loading: false,
      otmOptions: [
        { text: 'Tous les OTM', value: 'all' },
        { text: 'MTN', value: 'mtn' },
        { text: 'Moov', value: 'moov' }
      ],
      periods: [
        {
          label: '7 derniers jours',
          type: 'daily',
          dateFrom: moment()
            .subtract(6, 'days')
            .startOf('day')
            .toDate(),
          dateTo: moment()
            .endOf('day')
            .toDate()
        },
        {
          label: '6 derniers mois',
          type: 'monthly',
          dateFrom: moment()
            .subtract(5, 'months')
            .startOf('month')
            .toDate(),
          dateTo: moment()
            .endOf('month')
            .toDate()
        }
      ],
      chartData: {
        labels: [],
        datasets: [{ backgroundColor: '#1565B1', data: [] }]
      },
      chartOptions: {
        organizationId: '',
        period: {}
      },
      columns: [
        {
          field: 'createdAt',
          label: 'Date',
          class: 'containerLog-title',
          align: 'left'
        },
        {
          field: 'text',
          label: 'Information',
          class: 'containerLog-title',
          align: 'left'
        },
        {
          field: 'detail',
          label: 'Détail',
          class: 'containerLog-title',
          align: 'left'
        }
      ],
      disbursementOtm: 'all',
      reibursementOtm: 'all',
      selectedSfdId: 'all',
      activeChart: 'CreditLineProvision'
    }
  },

  mixins: [AccountMixin],

  computed: {
    ...mapGetters([
      'beneficiaryCounts',
      'totalBeneficiaryCounts',
      'beneficiaryCountsBySfd'
    ]),
    ...mapGetters('product', ['getCurrentProduct']),
    ...mapGetters('auth', ['user']),

    sfds(): Organization[] {
      return Organization.query()
        .whereIdIn(this.getCurrentProduct.sfdConcern)
        .where('type', 'sfd')
        .get()
    },

    beneficaryOptions(): Array<{ value: string; text: string }> {
      return [
        { value: 'all', text: 'Tous les SFD' },

        ...this.sfds.map((item: { id: string; name: string }) => ({
          value: item.id,
          text: item.name
        }))
      ]
    },

    beneficiariesCount(): number {
      return this.selectedSfdId === 'all'
        ? this.totalBeneficiary
        : this.beneficiaryCountsBySfd(this.selectedSfdId)
    },

    organization(): Organization {
      return Organization.loggedIn()
    },

    disbursementBalance(): number {
      switch (this.disbursementOtm) {
        case 'mtn':
          return this.soldeDecaissementMtn
        case 'moov':
          return this.soldeDecaissementMoov
        default:
          return this.soldeDecaissement
      }
    },

    refundBalance(): number {
      switch (this.reibursementOtm) {
        case 'mtn':
          return this.soldeRembourssementMtn
        case 'moov':
          return this.soldeRembourssementMoov
        default:
          return this.soldeRembourssement
      }
    },

    cardSubtitle(this: any) {
      return `Solde au ${this.$options.filters.date(
        new Date(),
        'DD/MM/YYYY HH:mm'
      )}`
    },

    logs() {
      return Logs.query()
        .orderBy('createdAt', 'desc')
        .all()
    },

    chartTitle(): string {
      return metricsTitles[this.activeChart]
    }
  },

  watch: {
    activeChart: 'fetchMetrics'
  },

  created(this: any) {
    Logs.api().fetch()
    Organization.api().fetch()
    this.fetchBeneficiaryCounts()
    this.chartOptions.period = this.periods[0]
  },

  mounted() {
    this.fetchMetrics()
  },

  methods: {
    ...mapActions(['fetchBeneficiaryCounts']),
    selectedSfdChange(value: string) {
      if (value == 'all') this.getTotalBeneficiary(null)
      else this.getTotalBeneficiary(value)
    },
    async fetchMetrics() {
      this.loading = true
      const response = await api.makeRequest({
        method: 'GET',
        url: metricsUrls[this.activeChart],
        params: {
          productId: this.getCurrentProduct.id,
          organizationId: this.chartOptions.organizationId,
          type: (this.chartOptions.period as any).type,
          dateFrom: moment((this.chartOptions.period as any).dateFrom).format(
            'YYYY-MM-DD'
          ),
          dateTo: moment((this.chartOptions.period as any).dateTo).format(
            'YYYY-MM-DD'
          )
        }
      })

      this.loading = false
      if (response.isSuccessful) {
        const data: number[] = []
        const labels: string[] = []
        let increment: string
        let dateFormat: string
        let labelFormat: string

        switch ((this.chartOptions.period as any).type) {
          case 'daily':
            increment = 'day'
            dateFormat = 'YYYY-MM-DD'
            labelFormat = 'dddd'
            break
          case 'monthly':
            increment = 'month'
            dateFormat = 'YYYY-MM'
            labelFormat = 'MMMM YYYY'
            break
          case 'yearly':
          default:
            increment = 'year'
            dateFormat = 'YYYY'
            labelFormat = 'YYYY'
            break
        }

        for (
          const d = moment((this.chartOptions.period as any).dateFrom);
          d.isSameOrBefore(moment((this.chartOptions.period as any).dateTo));
          d.add(1, increment as any)
        ) {
          const values = response.data[d.format(dateFormat)]
          labels.push(d.locale('fr').format(labelFormat))
          data.push(values ? values.value : 0)
        }

        this.chartData = {
          labels: labels as any,
          datasets: [
            {
              backgroundColor: metricsColors[this.activeChart],
              data: data as any
            }
          ]
        }
      }
    },

    downloadReport(type: string, title: string) {
      api.download(
        reportsUrls[type],
        {
          dateFrom: moment()
            .startOf('year')
            .format('YYYY-MM-DD'),
          dateTo: moment().format('YYYY-MM-DD'),
          productId: this.getCurrentProduct.id
        },
        this.getCurrentProduct.name.toUpperCase() +
          '--' +
          title.toUpperCase().replace(/ /g, '_') +
          '_DU_' +
          moment()
            .startOf('year')
            .format('DD_MM_YYYY') +
          '_AU_' +
          moment().format('DD_MM_YYYY') +
          '.xlsx'
      )
    }
  }
})
