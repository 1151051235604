
import Vue from 'vue'
import { mixins, Bar } from 'vue-chartjs'
const { reactiveProp } = mixins

export default Vue.extend({
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => ({
        maintainAspectRatio: false,
        responsive: true,
        barRoundness: 0.2,
        lineTension: 1,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label(tooltipItem: any, data: any) {
              return data['datasets'][0]['data'][tooltipItem['index']]
            }
          },

          backgroundColor: '#FFF',
          titleFontStyle: 'normal',
          titleFontSize: 12,
          titleFontColor: '#7F909E',
          titleFontFamily: 'Gibson Regular',
          bodyFontFamily: 'Gibson Regular',
          bodyFontColor: '#495254',
          bodyFontStyle: 'bold',
          xPadding: 40,
          yPadding: 6,
          bodyFontSize: 12,
          displayColors: false,
          cornerRadius: 6,
          borderColor: '#E8EFFB',
          borderWidth: 1
        },
        borderSkipped: 'bottom',

        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true
              },
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: '#7F909E',
                fontSize: 12,
                fontFamily: 'Gibson Regular'
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                callback: function(value: number) {
                  if (Number.isInteger(value)) {
                    return value
                  }
                }
              }
            }
          ]
        }
      })
    }
  },

  mounted(this: any) {
    this.renderChart(this.chartData, this.options)
  }
})
